#snake-body * {
  box-sizing: border-box;
  margin: 0px;
  border-radius: 5px;
}

#snake-body .fullWindow {
  padding: 0px;
}

#snake-body button, #snake-body select {
  background-color: inherit;
  border: inherit;
  color: inherit;
  padding: 0px;
  display: block;
  margin: 0px;
  font-size: inherit;
  font-family: inherit;
  appearance: none;
  cursor: pointer;
}


#snake-body {
  font-family: apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}

#snake-body a {
  background-color: inherit;
  border: none;
  padding: 0px;
  display: block;
  margin: 0px;
  font-size: inherit;
  font-family: inherit;
}


#snake-body #snake-full-window {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr auto;
  padding: 0px;
  height: 100vh;
  width: 100vw;
  background-color: #78FF72;
}

#snake-body #game {
  display: grid;
  flex-direction: column;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  grid-row: 1;
  grid-column: 1;
}


#snake-body #header-parent {
  display: flex;
  flex-direction: column;
  align-content:stretch;
  justify-content: flex-start;
  grid-row: 1;
  grid-column: 1;
  padding: 10px;
  background-color: #EBE0BC;
}


#snake-body #header-buttons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

#snake-body #home-button {
  background-color: inherit;
  position: absolute;
  top: 5px;
  left: 5px;
}

#snake-body #home-button img {
  display: block;
  height: calc(4rem + 12px);
  padding: 0.5rem 0px;
}


#snake-body #header-buttons h1 {
  font-size: 4rem;
  font-weight: bolder;
  color:#5DB5E8;
  padding: 0px;
  margin: 0px;
}

#snake-body #game-header-buttons > * {
  background-color: #5DB5E8;
  color: white;
  padding: 10px;
}


#snake-body #full-game-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

#snake-body #game-button-parent {
  display: flex;
  flex-direction: row;
  gap: 150px;
  justify-content: center;
  align-items: center;
}


#snake-body #points {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 14px;
  background-color: #EBE0BC;
  font-size: 2rem;
  font-weight: bold;
  color: #5DB5E8;
  box-shadow: 2px 1px 2px gray;
}

#snake-body #points.point-shake {
  animation-name: shake;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  background-color: #E49EFF;
  color: white;
}


#snake-body #game-header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

#snake-body #game-header-buttons {
  font-size: x-large;
}

#snake-body #start-button {
  box-shadow: 2px 1px 2px gray;
}

#snake-body #select-difficulty {
  box-shadow: 2px 1px 2px gray;
}

#snake-body #start-button.start-button-not-started {
  background-color: #E49EFF;
}

#snake-body #stats-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 5px;
  align-items: center;
  background-color: #EBE0BC;
  gap: 10px;
  box-shadow: 2px 1px 2px gray;
}

#snake-body #stat-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

#snake-body #stat-label {
  color:#5DB5E8;
  font-size: 1.5rem;
}

#snake-body #stat-content * {
  background-color: #5DB5E8;
  color: white;
  padding: 5px 10px;
  display:flex;
  justify-content: center;
  align-items: center;
}


#snake-body #stat-content > * {
  text-align: center;
}


#snake-body #game-wall {
  background-color: #5DB5E8;
  padding: 30px;
  margin: 20px;
  display: flex;
  flex-grow: 1;
}

#snake-body #game-content {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  grid-template-rows: repeat(14, 1fr);
  background-color: #78FF72;
  flex-grow: 1;
}

#snake-body .gameEnd {
  filter:blur(1px) grayscale(100%);
  animation-name: shake;
  animation-duration: 0.25s;
  animation-timing-function: linear;
}

#snake-body .box {
  border-radius: 35%;
}

#snake-body .snake-square {
  background-color: #5DB5E8;
}

#snake-body .apple-square {
  background-image: url('./redapple.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}


#snake-body #footer-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items:flex-start;
  background-color: #EBE0BC;
  border-radius: 5px;
  padding: 10px;
  grid-row: 2;
  grid-column: 1;
}


#snake-body #footerIconParent {
  display: flex;
  gap: 30px;
}

#snake-body #footerIconParent a {
  display: flex;
  align-content: center;
  position: relative;
  text-decoration: none;
  color: inherit;
}

#snake-body #footerIconParent a:hover {
  animation-name: shake;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}


#snake-body .footerIcon {
  width: 40px;
}

@keyframes shake {
  0% {
    transform: rotate(2deg);
  }
  30% {
    transform: rotate(-2deg);
  }
  68% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
