* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.full-window {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.site-full-window {
  --myLightGray: #F0F2F0;
  --myYellow: #FAD67D;
  --myDarkBlue: #A1A8B3;
  --myYellowBrown: #AB9E7D;
  --blue: #DEECFF;
  font-family: apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";

  overflow-y: scroll;
  background-color: var(--myLightGray);
  flex-grow: 1;
} 

.site-full-window * {
  border-radius: 5px;
}

.site-full-window a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.site-full-window a.resume-link {
  text-decoration: underline;
  color: inherit;
  display:inline;
}

.site-full-window .important {
  font-weight: bold;
}

.site-full-window button {
  display: block
}

.site-full-window .center-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.site-full-window #navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 2% 15%;
  border-bottom: solid black 2px;

  border-radius: 0;
}

.site-full-window .subnavbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.site-full-window #right-navbar {
  display: none;
}

.site-full-window .navbar-button {
  font-size: 1.5rem;
  background-color: var(--blue);
}

.site-full-window #navbar-my-name {
  font-size: 3rem;
}



.site-full-window .button-special-parent {
  padding: 4px 8px;
}

.site-full-window .button-special {
  padding: 8px 16px;
}

.site-full-window .button-special-parent-hover {
  padding: 0px;
}

.site-full-window .button-special-hover {
  padding: 12px 24px;
}

.site-full-window .home-content-parent {
  padding: 7.5% 15%;
}

.site-full-window .home-content-flex {
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.site-full-window .about-text {
  font-size: 1.25em;
}

.site-full-window #about-pic-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 75px;
}

.site-full-window .personal-pic {
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  min-width: 200px;
  min-height: 200px;
}

.site-full-window .all-button-special {
  transition: padding 0.5s, border-radius 0.5s;
}

.site-full-window .main-page-separator {
  border: solid black 1px;
}

.site-full-window #portfolio-section-container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.site-full-window .section-title {
  align-self: center;
  font-size: 2rem;
  text-decoration: none;
  font-weight: normal;
}

.site-full-window .subsection-title {
  align-self: center;
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: normal;
}

.site-full-window .project-link-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.site-full-window #project-container {
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(1rem, max-content));
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 60px;
  align-items:stretch;
}

.site-full-window .individual-project {
  color: black;
  background-color: var(--blue);

  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 20px;
  border-radius: 15px;
  border: solid 1px black;
}

.site-full-window .project-content {
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 10px;
}

.site-full-window .img-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  
  align-self: stretch;

  overflow-x: scroll;
}

.site-full-window .img-container img {
  max-width: 100%;
  max-height: 400px;
}


.site-full-window .project-description-container {
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.site-full-window .project-description-icons {
  align-self: center;

  display: flex;
  gap: 4px;
}

.site-full-window .project-description-container .description-selector {
  height: 0.5em;
  width: 0.5em;
}

.site-full-window .project-description-container .description-selector.selected {
  opacity: 1;
}

.site-full-window .project-description-container .description-selector.not-selected {
  opacity: 0.5;
}

.site-full-window .description-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: hidden;
}

.site-full-window .description-text-scrolling {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  min-width:max-content;
  gap: 10px;
}

.site-full-window .description-scrolling-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.site-full-window .no-display {
  visibility: hidden;
  pointer-events: none;
}

.site-full-window .img-container {
  overflow-x: hidden;
}

.site-full-window .img-scrolling {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  min-width:max-content;
  gap: 10px;
}

.site-full-window .individual-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-full-window .individual-image img {
  object-fit: contain;
  height: 100%;
}

.site-full-window .description-arrow:hover {
  filter: brightness(80%)
}

.site-full-window #footer-parent {
  padding: 10% 10%;
  background-color: black;
  color: var(--myLightGray);

  display: flex;
}

.site-full-window #footer-grid-parent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 45px;

  flex-grow: 1;
}

.site-full-window #middle-footer-flex {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.site-full-window #footer-icon-parent {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.site-full-window .footer-icon {
  width: 40px;
}

.site-full-window #contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site-full-window #contact-title-footer {
  text-align: center;

  font-size: 1.5rem;
  text-decoration: underline;
  margin-bottom: 1rem;
  font-weight: normal;
}

.site-full-window.not-found-full-window {
  display: flex;
  flex-direction: column;
}

.site-full-window #not-found-content {
  flex-grow: 1;
}

.site-full-window #not-found-content a {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
}

.site-full-window .not-found-flex {
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.site-full-window #not-found-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.site-full-window .doc-flex {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.site-full-window .doc-flex a {
  text-decoration: underline;
}

.site-full-window .doc-code {
  font-family: 'Courier New', Courier, monospace;
}

.site-full-window .doc-curl {
  font-family: 'Courier New', Courier, monospace;
  border-radius: 5px;
  border: solid 1px black;
  padding: 10px;
  word-wrap:break-word;
}

@media (max-width: 600px) {
  .site-full-window #about-pic-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .site-full-window #project-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .site-full-window #footer-grid-parent {
    grid-template-columns: 1fr;
  }

  .site-full-window .hide-footer-mobile {
    display: none;
  }
}