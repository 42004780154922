#jbMainContainer {
    --grayGreen: #9BC2BF;
    --lightBlue: #73FFF3;
    --beige: #FFE5C4;
    --purple: #5D78C2;
    --lightPurple: #D9E2FA;
}


#jbMainContainer {
    display: flex;
    flex-direction: column;

    background-color: #ffe4c4;
    height: 100vh;
    width: 100vw;
    font-family: 'roboto', apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    ;
}

#jbMainContainer #navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--grayGreen);
    color: white;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}


#jbMainContainer #full-post-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    min-height: 0;

}

#jbMainContainer .attr {
    text-align: center;
    color: var(--purple);
    font-size: 0.5rem;
}

#jbMainContainer .jb-logo-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 1;
    justify-content: center;
}

#jbMainContainer .jb-logo {
    height: 3.5rem;
}

#jbMainContainer .site-header {
    color: var(--purple);
    text-align: center;
    font-size: 3.5rem;
}




#jbMainContainer .navBarLinks {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 10px;
    gap: 10px;
}

#jbMainContainer .navBarLinks svg {
    font-size: 3rem;
}


#jbMainContainer .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
}

#jbMainContainer .form {
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    gap: 50px;
    border: solid 3px var(--grayGreen);
    padding: 20px;
    border-radius: 5px;
    min-width: min(500px, 75%);
    color: var(--grayGreen)
}

#jbMainContainer .form h1 {
    text-align: center;
}

#jbMainContainer .form label {
    display: flex;
    flex-direction: column;
}



#jbMainContainer .mainContent {
    flex-grow: 1;
    background-color: aqua;
}

#jbMainContainer .postPage {
    display: flex;
    justify-content: center;
    overflow: hidden;

    min-height: 0;
    padding: 15px;
}

#jbMainContainer .post-list-parent {
    display: flex;
    flex-direction: column;
    gap: 10px;

    min-height: 0;

    flex: 0 1 500px;
}

#jbMainContainer .post-list {
    min-height: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

#jbMainContainer .post-list-parent .new-post-button {
    align-self: center;
}

#jbMainContainer .individualPost {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 1px var(--purple);
    gap: 20px;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    color: var(--purple);
}


#jbMainContainer .individualPost h1 {
    font-size: medium;
    margin: 10px;
}

#jbMainContainer .post-content {
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 20px;

}

#jbMainContainer .post-content h1 {
    font-size: 2rem;
}



#jbMainContainer .content {
    font-size: 1.25rem;
    align-self: flex-start;
    text-align: left;
}

#jbMainContainer .author {
    font-size: 1rem;
    align-self: flex-start;
}

#jbMainContainer .postButtonContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#jbMainContainer button.log-in-error {
    margin-top: 20%;
}