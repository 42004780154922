#strangerMain * {
  margin: 0;
  box-sizing: border-box;
  border-radius: 5px;
}


#strangerMain button, #strangerMain a {
  border: none;
  background-color: inherit;
  font: inherit;
  margin: 0;
  padding: 0px;
  display: block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

#strangerMain a {
  display: flex;
  justify-content: center;
  align-items: center;
}

#strangerMain svg {
  display: block;
}

#strangerMain .generic-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

#strangerMain {
  --lightYellow: #FAF7E1;
  --blue: #99D1FF;
  --lightPink: #F3C8F7;
  --brightPink: #EE7FF9;
  --darkBlue: #90C5F0;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  width: 100vw;
  background-color: var(--lightYellow);
  font-family: apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}


#strangerMain #Navbar {
  background-color: var(--blue);
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row: 1;
  grid-column: 1;
  min-height: 4rem;
}

#strangerMain #Navbar input {
  border: none;
  background-color: white;
  padding: 1px 6px;
}



#strangerMain #leftNavbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

#strangerMain #rightNavbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
  min-width: 0;
}

#strangerMain .navbar-icons {
  height: 3rem;
  cursor: pointer;
}

#strangerMain .icon-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 3px;

  height: 3rem;
  color: inherit;
  text-decoration: none;
}

#strangerMain .icon-wrapper p {
  font-size: 0.65rem;
  text-align: center;
}


#strangerMain #navbar-not-logged-in {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}

#strangerMain #navbar-logged-in {
  gap: 20px;
  align-items: center;
}

#strangerMain #logged-in-actions {
  gap: 10px;
}

#strangerMain #postFilter:focus {
  outline: none;
}

#strangerMain #mainContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;

  min-height: 0;
  grid-row: 2;
  grid-column: 1;
}

#strangerMain #post-page-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
  position: relative;
  overflow-y: scroll;

  flex: 1 1 500px;
  min-height: 0;
  padding: 20px;
  grid-template-columns: 2;
}

#strangerMain #post-header-container {
  flex-grow: 0;
  flex-shrink: 1;
  flex-wrap: wrap;
  gap: 10px;
  color: var(--blue)
}

#strangerMain #logo-container {
  height: 4rem;
}

#strangerMain #post-header {
  text-align: center;
  font-size: 3rem;
}


#strangerMain #show-submission-page {
  position: absolute;
  top: 10px;
  right: 10px;
}

#strangerMain #show-message-page {
  position: absolute;
  top: 10px;
  left: 10px;
}

#strangerMain #post-submission-parent {
  padding: 20px;
  display: flex;
  gap: 30px;
  flex-direction: column;

  grid-row: 1;
  grid-column: 2;
}

#strangerMain #post-submission-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#strangerMain #postSubmitContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#strangerMain #post-submission-form input, #strangerMain #post-submission-form textarea {
  border: none;
}

#strangerMain #post-submit-button {
  background-color: var(--blue);
  color: white;
  padding: 5px 10px;
}


#strangerMain .submission-detail {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

#strangerMain #submissionPostDescription {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

#strangerMain #submissionPostDescriptionInput {
  height: 5rem;
}

#strangerMain #submissionPostWillDeliver {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 15px;
}



#strangerMain .login-input-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-shrink: 1;
}

#strangerMain #loginFormParent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 15px;
  justify-content: flex-end;
}

#strangerMain #loginFormContainer {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

#strangerMain #loginSubmitContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

#strangerMain #header-x {
  font-size: 1rem;
}



#strangerMain #searchContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 1.5rem;
  background-color: white;
}

#strangerMain #searchContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

#strangerMain #all-posts-plus-attribution {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: scroll;
  align-items: center;
  gap: 50px;
  flex-grow: 1;
}

#strangerMain #all-posts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 10px;
  justify-content:space-evenly;
  margin-left: auto;
  margin-right: auto;
}

#strangerMain #attribution {
  font-size: 0.75rem;
  width: 30%;
  text-align: center;
}

#strangerMain .individual-post-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: 5px;
  min-width: 250px;


  flex: 1 1 450px;
  padding: 10px;
  background-color: var(--blue);
}

#strangerMain .individual-post-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 30px;

  flex: 1 1 400px;
  padding: 15px;
  background-color: var(--lightYellow);
}

#strangerMain .individual-post {
  display: flex;
  gap: 15px;
  flex-direction: column;
  color: black;
  max-height: 350px;
  overflow-y: scroll;
}

#strangerMain .button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

#strangerMain .my-post {
  background-color: var(--lightPink);
}

#strangerMain .my-post h3 {
  color: var(--brightPink);
}

#strangerMain .post-button {
  background-color: var(--blue);
  color: white;
  padding: 5px;
}

#strangerMain .my-post-delete {
  background-color: var(--lightPink);
}


#strangerMain .message-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  flex: 0 1 300px;
}

#strangerMain .message-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
}

#strangerMain .message-form textarea {
  flex-grow: 1;
  border: none;
}

#strangerMain .send-message-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#strangerMain .send-message-container button {
  padding: 5px 15px;
  border: none;
  font-size: 0.75rem;
  background-color: var(--lightPink);
  
  color: white;
}

#strangerMain .send-message-button {
  padding: 5px 15px;
  background-color: white;
  border: none;
  font-size: 0.75rem;

  color: var(--darkBlue);
}

#strangerMain #auth-user-post-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

#strangerMain .sidebar {
  overflow-y: scroll;
  min-height: 0;
  flex: 0 1 300px;
  border-radius: 0;
  position: relative;
  padding: 15px;
}

#strangerMain .sidebar-close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--darkBlue)
}

#strangerMain .sidebar-content-container {
  border: 10px solid var(--lightPink);
  position: relative;
  padding: 20px;
}

#strangerMain #message-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;

  grid-column: 2;
  grid-row: 1;
}

#strangerMain #message-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}

#strangerMain #message-button {
  background-color: var(--blue);
  color: white;
  padding: 5px 10px;
}

#strangerMain .sidebar-header {
  text-align: center;
  color: var(--blue);
  font-size: 1.5rem;
}



#strangerMain .individual-message {
  padding: 20px;
  border-bottom: 2px solid var(--blue);
  border-radius: 0;
}

#strangerMain .individual-message:last-child {
  border-bottom: none;
}


@media (max-width: 900px) {
  #strangerMain #Navbar {
    flex-direction: column;
    align-items: center;
  }

  #strangerMain #loginFormContainer {
    flex-direction: column;
  }

  #strangerMain #post-header {
    font-size: 1.5rem;
  }

  #strangerMain #mainContent {
    flex-direction: column;
  }

  #strangerMain #logo-container {
    height: 2rem;
  }

  #strangerMain .individual-post-container {
    flex-direction: column;
  }

  #strangerMain .individual-post-content {
    flex-basis:auto;
  }

  #strangerMain .message-content {
    flex-basis: 100px;
  }
  
}